import React from 'react';
import Helmet from 'react-helmet';
import settings from '../../settings';
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";

import Reveal from '../../components/Reveal';

import GoogleMap from './GoogleMap';

import ContactForm from './Form';

export default function () {

	return (
	<Layout>
		<Helmet>
			<title>Contact Us / Hire • Blackstone VT</title>
			<link rel="canonical" href={`${settings.host.url}/contact`} />
			<meta
				name="description"
				content="Contact us to request a quote for our any of our services. We're responsive and sincere regardless of your choice to give us the opportunity to serve you."
			/>
		</Helmet>
	  <Banner>Let's talk soon</Banner>
	  <div className="container">
		<div className="row">
		  <div className="col-lg-6 offset-lg-3"style={{paddingTop:'20px'}}>
		  	<Reveal><h1 style={{fontSize:'28px',fontWeight:'100'}}>Request a quote / Contact us <span role="img" aria-label="Finger Pointing Down">👇</span></h1></Reveal>
		  	<hr/>
			<ContactForm/>
			<hr/>
			<Reveal>
			<h4>Hours of operation</h4>
			<ul style={{
				paddingBottom: '40px',
			}}>
				<li>M-F: 7am-5pm</li>
				<li>Sat: 9am-5pm</li>
				<li>Sun: Scheduled work only</li>
			</ul>
			</Reveal>

			<hr/>
			<p>Headquartered in Southern Vermont, serving New England.</p>
		  </div>
		</div>
	  </div>
	  <GoogleMap/>
	</Layout>
	);
}